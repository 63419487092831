import _, { isEqual } from 'lodash';
import React, { memo, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import appConfig from '../../../../../appConfig';
import http from '../../../../../Common/Services/HttpService';
import { DEPENDENT_REPLATIONSHIP, IS_DEPENDENT_MODIFY, IS_QLE_DEPENDENT_NOT_MODIFIED, UPDATE_CART_ENROLLMENT, UPDATE_CART_ENROLLMENT_COVERAGE_LEVEL } from '../../../../../Store/Slices/EmployeeProfile';
import { UPDATE_ENROLLMENT_DATA } from '../../../../../Store/Slices/Enrollment';
import { onLoadAPI } from '../../../../../Store/Slices/Entities/response';
import { CLEAR_MESSAGE, SET_ALERT_MESSAGE, UPDATE_LOADING } from '../../../../../Store/Slices/InsuredInfo';
import { PREVIOUS_STEP } from '../../../../../Store/Slices/StepForm';
import resumeContent from '../../../../Molecules/CustomSection/Content/ResumeContent.json';
import { filterSelectedSteps, getWaivedSteps, updateStepperNavigatorInfo } from '../../../../Utils/Form/StepperHelper';
import { createOrUpdateInsured } from '../../Actions/createOrUpdateInsured.js';
import { deleteInsured } from '../../Actions/deleteInsured.js';
import { getErrorMessages, removeUnwantedProps } from '../../Helper/MappingHelper';
import { useNavigationHook } from '../../Hooks/NavigationHook';
import DependentList from './DependentList';
import DependentModel from './DependentModel';

import './dependent-styles.css';
import { CommonConstants } from '../../../../../Common/Constants';

const Dependent = memo((props) => {

  const { currentStep, previousStep, formsConfig, stepKeys, handleNextStep, handlePreviousStep, handleGoToSpecificStep, handleSetStepKeys } = useNavigationHook();
  const { DependentInformation } = useSelector((state) => state.insuredInfo);
  const { errorMessage, successMessage } = DependentInformation;
  const [show, setShow] = useState(false);
  const [editIndex, setEditIndex] = useState(null);
  const [btnLabel, setBtnLabel] = useState("Continue")
  const [modalKey, setModalKey] = useState(null);
  const [headerText, setHeaderText] = useState('Add Dependent');
  const [modalAction, setModalAction] = useState(false)
  const [isEditModal, setisEditModal] = useState(false)
  const [initialDependentData, setInitialDependentData] = useState(null);
  const [previousDependentData, setPreviousDependentData] = useState(null);

  const productList = useSelector((storeState) => storeState?.process?.productList || []);

  const { dependentRelationShip } = useSelector((storeState) => storeState.employeeProfile);
  const storeFromRedux = useSelector((storeState) => storeState);
  const { loggedUserEntity = {} } = useSelector(({ auth }) => auth);
  const insuredDependents = useSelector((state) => state.enrollment?.enrollmentData?.contract?.insureds || []);
  const employeeProfile = useSelector((storeState) => storeState?.employeeProfile?.employeeProfile);
  const enrollmentData = useSelector((state) => state.enrollment?.enrollmentData);
  const gbpResources = useSelector((storeState) => storeState?.employeeProfile?.getGBPResourcesForMember);
  const enrollmentBuid = useSelector((storeState) => storeState.process?.enrollmentProcess?.[0]?.[0]?._Result?.bUID) ?? null;
  const enrorsementEligibleProducts = useSelector((storeState) => storeState?.entities?.response?.onLoadResponseList?.EndorsementListOfEligibleProducts?._Result);
  const qleEndorsementProcessBuid = useSelector((storeState) => storeState?.entities?.response?.onLoadResponseList?.initNewIndividualEndorsementProcess?._Result?.processEntity?.buid);
  const endorsementData = useSelector((state) => state.enrollment?.endorsementData);
  const enrollmentPeriodKind = useSelector((storeState) => storeState?.process?.contractsLinearOrNonLinear?._Result?.currentEnrollmentPeriod?.enrollmentPeriod?.enrollmentPeriodKind);
  const endorsementDataInsuredDependents = endorsementData?.contract?.insureds || []
  const reasonType = useSelector(
    (storeState) => storeState?.employeeProfile?.reasonType
  );
  const adminReasonType = useSelector(
    (storeState) => storeState?.employeeProfile?.adminReasonType
  );
  const isAdmin = useSelector(
    (storeState) => storeState?.entities?.response?.onLoadResponseList?.AdminEndorsementProcess?._Result?.processEntity?.buid
  );
  let currentBenifitendorsementProcessBuid = useSelector((storeState) => storeState?.entities?.response?.onLoadResponseList?.initEndorsementProcessData?._Result?.processEntity?.buid);
  const adminendorsementProcessBuid = useSelector((storeState) => storeState?.entities?.response?.onLoadResponseList?.AdminEndorsementProcess?._Result?.processEntity?.buid);
  currentBenifitendorsementProcessBuid = currentBenifitendorsementProcessBuid ? currentBenifitendorsementProcessBuid : adminendorsementProcessBuid
  const disabledData = reasonType === "OE" && currentBenifitendorsementProcessBuid ? true : false
  const annualEnrollmentandNH = (reasonType === "AE" || reasonType === "NH" || adminReasonType === 'ADE') && currentBenifitendorsementProcessBuid ? true : false
  let isdisabled = (qleEndorsementProcessBuid || annualEnrollmentandNH || disabledData || isAdmin)
  const clientId = useSelector(
    (storeState) =>
      storeState?.auth?.loggedUserEntity?.data?.personDetails?._Result?.[0]?.legalEntity?.identifier?.externalClientId?.externalClientId
  );
  
  const additionalDatas = useSelector((storeState) =>storeState.process?.contractsLinearOrNonLinear?._Result?.contract?.additionalDatas);
  const hasVoyaWL = additionalDatas?.some((data) => data.fieldCode === 'DD_00159' && data.fieldValue === 'Yes');
  const isQLEEnrollment = useSelector((storeState) => storeState?.enrollment?.isQLE)
  const eligibleProductList = useSelector((storeState) => storeState?.process?.selectedProductList) || [];
  let findTravelProduct = eligibleProductList?.filter(item => item?.productFamily === "Travel Insurance");
  const requiredProductList = eligibleProductList?.filter(item => item?.productFamily !== "Travel Insurance");
  const filterTravelProduct = (qleEndorsementProcessBuid) && clientId === "NATCOL" && findTravelProduct?.[0]?.productFamily === 'Travel Insurance'
  let selectedProductList = filterTravelProduct ? requiredProductList : eligibleProductList
  if(hasVoyaWL && !selectedProductList.some(item => item.productFamily === CommonConstants.VOYA_WL)&&previousStep === 'EnrollmentSummary'){
    selectedProductList = [
      ...selectedProductList,
      {
        productFamily: CommonConstants.VOYA_WL,
        bUID: CommonConstants.VOYA_WL,
        
      }
    ];
  }

  const dispatch = useDispatch();

  const handleClose = () => {
    setShow(false);
    setEditIndex(null);
  };

  const handleShow = () => {
    dispatch(CLEAR_MESSAGE('DependentInformation'));
    setShow(true);
    setModalKey(Date.now()); // Update the key with the current timestamp
    updateHeaderText('add');
  };

  const modifyDependent = (dependent) => {
    if (dependent) {
      delete dependent.data;
      dependent.editDependentBUID = dependent.mainBuid;
    }
    return dependent;
  };

  const validateContractData = async () => {
    try {
      const resp = await http.post(appConfig.SSP_MID_URL + '/v1/dependent-information-submit', { enrollmentProcessBuid: enrollmentBuid });
      if (resp && resp.data) {

        const data = { _Result: resp?.data?.data?.validateContractData };
        dispatch(onLoadAPI({ ValidateContractData: data }));
        dispatch(UPDATE_ENROLLMENT_DATA(resp?.data?.data?.validateContractData));
      } else {
        //  toast.error('Enrollment failed');
        dispatch(SET_ALERT_MESSAGE({
          type: 'error',
          errorCode: '520',
          id: 'dependentInformationSubmit',
          show: false,
          page: currentStep
        }));
      }
    } catch (error) {
      // toast.error('Enrollment failed - Invalid response');
      dispatch(SET_ALERT_MESSAGE({
        type: 'error',
        errorCode: '520',
        id: 'dependentInformationSubmit',
        show: false,
        page: currentStep
      }));
    }
  };

  const subscribedProduct = enrollmentData?.contract?.subscribedElements?.filter((res=> res?.subscribedProduct?.status === 'cActive')).map((buid) => {
    return {
      bUID: buid?.subscribedProduct?.productCode?.buid,
      className: buid?.subscribedProduct?.className
    }
  }
  )

  let getEligibleProduct = filterTravelProduct ? enrorsementEligibleProducts?.filter(item => item?.memberOfferedProduct?.product?.productFamily !== "Travel Insurance")?.map(buid => ({
    bUID: buid?.memberOfferedProduct?.bUID,
    className: buid?.memberOfferedProduct?.className
  })) : enrorsementEligibleProducts?.map((buid) => {
    return {
      bUID: buid?.memberOfferedProduct?.bUID,
      className: buid?.memberOfferedProduct?.className
    }
  }
  )
  //const eligibleProduct = getEligibleProduct?.filter(obj1 => !subscribedProduct?.find(obj => obj.bUID === obj1.bUID)) || []
  const eligibleProduct = getEligibleProduct || [];
  let isdependentEligibeleProduct = (qleEndorsementProcessBuid || annualEnrollmentandNH) && eligibleProduct.length > 0
  const qleSubscribedProduct = async () => {
    try {
      dispatch(UPDATE_LOADING(true));
      const resp = await http.post(appConfig.SSP_MID_URL + '/subscribeThisListOfProducts', {
        processEntity: enrollmentBuid,
        eligibleProduct: eligibleProduct,
        currentStepInfo: {
          "portalStep": currentStep
        }
      });
      if (resp.data && resp.status === 200) {
        dispatch(onLoadAPI({
          isAPICallInProgress: false,
        }))
      }
    } catch (e) {
      console.error(e);
    } finally {
      dispatch(UPDATE_LOADING(false));
    }
  }

  const handleParentBack = () => {
    dispatch(CLEAR_MESSAGE('DependentInformation'));
    if (previousStep === 'EnrollmentSummary') {
      handleGoToSpecificStep(previousStep);
    } else {
      handlePreviousStep();
    }
  };

  const checkIfDependentModified = (initialValue, currentValue) => {
    if (initialValue.length !== currentValue.length) {
      return true;
    }

    const isAnyDependentModified = initialValue
      .map((iv) => {
        const { data, ...initialData } = iv;
        const dependent = currentValue.find((cv) => cv.mainBuid === iv.mainBuid);
        if (!dependent) return true;

        const { data: cData, ...currentData } = dependent;
        return !isEqual(initialData, currentData);
      })
      .some((isDepModified) => isDepModified === true);

    return isAnyDependentModified;
  };

  const dependentContinue = async () => {
    dispatch(CLEAR_MESSAGE('DependentInformation'));
    const isDependentModified = checkIfDependentModified(initialDependentData, dependentsTable);
    dispatch(IS_DEPENDENT_MODIFY(isDependentModified));
    dispatch(IS_QLE_DEPENDENT_NOT_MODIFIED(true))
    dispatch(UPDATE_LOADING(true));
    try {
      if (previousStep === 'EnrollmentSummary') {
        dispatch(PREVIOUS_STEP({ previousStep: '' }));
        if (isDependentModified || isdependentEligibeleProduct) {
          await validateContractData();
          const forms = { ...(formsConfig.forms ?? {}) };
          const stepKeys = filterSelectedSteps(forms, selectedProductList, productList, storeFromRedux.insuredInfo);
          // const VoyaWLStepIndex = stepKeys.indexOf("WLCoverage");
          // if (VoyaWLStepIndex > -1 && enrollmentPeriodKind !== "cAnnualEnrollment") {
          //   stepKeys.splice(VoyaWLStepIndex, 1);
          // }
         
          const waivedSteps = getWaivedSteps(forms, stepKeys, storeFromRedux.insuredInfo, isDependentModified, qleEndorsementProcessBuid);
          /*    props.SetContentData((prevContentData) => {
               return {
                 ...prevContentData,
                 stepKeys,
                 waivedSteps,
               }
             }); */
          handleSetStepKeys(stepKeys);
          updateStepperNavigatorInfo('DependentInformation', dispatch, { dependentsTable, forms, stepKeys, selectedProductList, productList, gbpResources });
          handleNextStep();
        } else {
          //props?.contentData?.goBackStep(props?.contentData.stepKeys.indexOf(previousStep));
          handleGoToSpecificStep(previousStep);
        }
      } else {
        await validateContractData();

        updateStepperNavigatorInfo('DependentInformation', dispatch, { dependentsTable, forms: formsConfig.forms ?? {}, stepKeys, selectedProductList, productList, gbpResources });
        handleNextStep();
      }
    } catch (e) {
      console.error(e)
    } finally {
      dispatch(UPDATE_LOADING(false));
    }
  };

  function updateDependentAddress(dependent, employeeProfile) {
    dependent.dependentAddressLine1 = employeeProfile.line1 || '';
    dependent.dependentAddressLine2 = employeeProfile.line2 || '';
    dependent.dependentCity = employeeProfile.city || '';
    dependent.dependentState = employeeProfile.state || '';
    dependent.dependentZipCode = employeeProfile.zipCode || '';
    dependent.dependentCountry = employeeProfile.country || '';
    dependent.correspondences = employeeProfile.correspondences || [];
    return dependent;
  }

  const handleSubmit = (dependent) => {
    dispatch(CLEAR_MESSAGE('DependentInformation'));
    let externalStepContext = {
      portalStep: currentStep,
      wynsureStep: enrollmentData?.processEntity?.currentStepNb
        ? JSON.stringify(enrollmentData?.processEntity?.currentStepNb)
        : '',
      reachedPreConfirmation: 'no',
    };

    if (dependent.liveInSameAddress === true) {
      dependent = updateDependentAddress(dependent, employeeProfile);
    }

    if (editIndex !== null) {
      // handleUpdate(dependent);
      const updatedDependent = modifyDependent(dependent);

      dispatch(
        createOrUpdateInsured(
          updatedDependent,
          storeFromRedux,
          loggedUserEntity,
          true,
          externalStepContext,
          currentStep
        )
      );
    } else {
      dispatch(
        createOrUpdateInsured(
          dependent,
          storeFromRedux,
          loggedUserEntity,
          false,
          externalStepContext,
          currentStep
        )
      );
    }
    // if (previousStep === 'EnrollmentSummary') {
    //   if (isdependentEligibeleProduct) {
    //     dispatch(UPDATE_CART_ENROLLMENT([]));
    //     dispatch(UPDATE_CART_ENROLLMENT_COVERAGE_LEVEL([]));
    //   }
    // }
    handleClose();
    setBtnLabel("Continue")
  };

  const formatSSN = (value) => {
    if (!value) return ''; // return empty string if value is null or undefined
    const cleanSSN = value.replace(/[^\d]/g, '');
    return cleanSSN
      .slice(0, 9)
      .replace(/(\d{0,3})(\d{0,2})(\d{0,4})/, (match, p1, p2, p3) => {
        return [p1, p2, p3].filter((part) => part.length > 0).join('-');
      });
  };

  const updateHeaderText = (action) => {
    if (action === 'add') {
      setHeaderText('Add Dependent');
      setModalAction(false)
    } else if (action === 'edit') {
      setModalAction(true)
      setHeaderText('Edit Dependent');
    }
  };
  const handleEdit = (index, isEdit) => {
    setisEditModal(isEdit)
    dispatch(CLEAR_MESSAGE('DependentInformation'));
    setEditIndex(index);
    handleShow();
    updateHeaderText('edit');
    setBtnLabel("Continue")

  };

  const handleDelete = (dependentInfo) => {
    dispatch(CLEAR_MESSAGE('DependentInformation'));
    dispatch(deleteInsured(enrollmentBuid, dependentInfo?.dependentBuid, currentStep));
    setBtnLabel("Continue")
  };


  const dependentsTable = useMemo(() => {
    if (insuredDependents.length > 0) {
      const tableData = [];

      insuredDependents.forEach((dependent) => {
        if (!dependent?.insured?.terminationDate) {
          if (dependent?.insured?.mainOwnerRelationshipToInsured) {
            let dependentData = {
              mainBuid: dependent.bUID,
              id: dependent?.person?.identifier?.bUID || '',
              SSN: formatSSN(dependent?.person?.data?.actor?.sSN) || '',

              dependentAddressLine1:
                dependent?.person?.data?.correspondences[0]?.address?.line1 || '',
              dependentAddressLine2:
                dependent?.person?.data?.correspondences[0]?.address?.line2 || '',
              dependentBirthDate: dependent?.person?.data?.actor?.birthDate || '',
              dependentCity:
                dependent?.person?.data?.correspondences[0]?.address?.zipCodeAndCity?.city || '',
              dependentCountry: dependent?.person?.data?.correspondences[0]?.address?.country || '',
              dependentDisabled:
                dependent?.insured?.additionalData?.[0]?.valueCode || '',
              dependentFirstName: dependent?.person?.data?.actor?.shortName || '',
              dependentFullTimeStudent: dependent?.insured?.additionalData?.find(
                (additional) => additional.fieldName === 'ISCHFTSTUDENT'
              )
                ? 'yes'
                : 'no',
              dependentGender: dependent?.person?.data?.actor?.gender || '',
              dependentLastName: dependent?.person?.data?.actor?.name || '',
              dependentMiddleName: dependent?.person?.data?.actor?.middleName1 || '',
              dependentRelationship: dependent?.insured?.mainOwnerRelationshipToInsured || '',
              dependentState: dependent?.person?.data?.correspondences[0]?.address?.state || '',
              dependentZipCode:
                dependent?.person?.data?.correspondences[0]?.address?.zipCodeAndCity?.zipCode || '',
              liveInSameAddress:
                dependent?.person?.data?.actor?.preferredContactChoice === 'pcActorContact'
                  ? true
                  : false,
              isDependent: true,
              data: { ...dependent },
            };
            tableData.push(dependentData);
          }
        }

      });

      return tableData;
    }
    return [];
  }, [insuredDependents]);
  const previousDependentTable = useMemo(() => {
    if (endorsementDataInsuredDependents.length > 0) {
      const InitialTableData = [];



      endorsementDataInsuredDependents.forEach((dependent) => {
        if (!dependent?.insured?.terminationDate) {
          if (dependent?.insured?.mainOwnerRelationshipToInsured) {
            let dependentData = {
              mainBuid: dependent.bUID,
              id: dependent?.person?.identifier?.bUID || '',
            };
            InitialTableData.push(dependentData);
          }
        }
      });

      return InitialTableData;
    }
    return [];
  }, [endorsementDataInsuredDependents]);
  const hasErrors = getErrorMessages(currentStep);
  useEffect(() => {
    const onLoadDependentPage = async () => {
      try {
        dispatch(UPDATE_LOADING(true));

        const reduxState = removeUnwantedProps(storeFromRedux, resumeContent.resumeUnwantedProps);
        const stepContext = { portalStep: currentStep, reduxState: _.omit(reduxState, ["insuredInfo"]) }

        const response = await http.post(appConfig.SSP_MID_URL + '/v1/dependent-information-load', { enrollmentProcessBuid: enrollmentBuid, stepContext });
        if (response?.data?.data?.updateProcess?.data && !isdependentEligibeleProduct) {
          dispatch(UPDATE_ENROLLMENT_DATA(response?.data?.data?.updateProcess?.data))
        }
        if (response?.data?.data?.getCoveredPersonTypeConfiguration) {
          dispatch(DEPENDENT_REPLATIONSHIP(response?.data?.data?.getCoveredPersonTypeConfiguration?.data || []));
        }
      } catch (e) {
        console.error(e);
        dispatch(SET_ALERT_MESSAGE({
          type: 'error',
          errorCode: '520',
          continue: true,
          id: 'dependentInformationLoad',
          show: true,
          page: currentStep,
        }));
      } finally {
        dispatch(UPDATE_LOADING(false));
      }
    };

    const fetchData = async () => {
      if (enrollmentBuid) {
        if (isdependentEligibeleProduct) {
          await qleSubscribedProduct()
          await onLoadDependentPage();
        } else {
          onLoadDependentPage();
        }
      }
    };

    fetchData();

    return () => {
      dispatch(CLEAR_MESSAGE('DependentInformation'));
    }
  }, [enrollmentBuid]);


  useEffect(() => {
    let label = previousStep === "EnrollmentSummary" && !isdependentEligibeleProduct ? "Continue to Summary" : "Continue"
    setBtnLabel(label)
  }, [previousStep])

  useEffect(() => {
    if (!initialDependentData)
      setInitialDependentData(dependentsTable);
  }, [dependentsTable]);
  useEffect(() => {
    if (!previousDependentData)
      setPreviousDependentData(previousDependentTable);
  }, [previousDependentTable]);


  return (
    <div className="row">
      <div className="col-12">
        {errorMessage && <div className="text-danger">{errorMessage}</div>}
        {successMessage && <div className="text-success-contrast">{successMessage}</div>}

        <div className="mt-4 mb-2">
          Click on the 'Add Dependent' button to add eligible dependents you want to cover
          under your insurance plans. If you do not wish to add any dependent click on the
          'Continue' button to proceed further.
        </div>

        {dependentsTable.length > 0 && (
          <DependentList
            Dependents={dependentsTable}
            onEdit={handleEdit}
            onDelete={handleDelete}
            initialDependents={dependentsTable}
            relation={props.relation}
            personBuid={props.personBuid}
            currentStep={currentStep}
            updatedependent={props.updatedependent}
            previousDependentData={previousDependentData}
            disabledData={disabledData}

          />
        )}
        <button type="button" role="button" className="btn btn-primary btn-primary-contrast" onClick={handleShow}>
          Add Dependent
        </button>
      </div>

      <div
        className={`modal ${show ? 'show' : ''} add-dependent`}
        style={{ display: show ? 'block' : 'none' }}
      >
        <div
          className="modal-dialog modal-lg"
          role="document"
          style={{ maxWidth: '800px', maxHeight: '600px' }}
        >
          <div className="modal-content">
            <div className="modal-header">
              <div className="modal-title">{headerText}</div>
              <button type="button" className="close" onClick={handleClose}>
                <span>&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <DependentModel
                key={modalKey} // Use the modalKey here
                onSubmit={handleSubmit}
                onCancel={handleClose}
                dependentOptions={dependentsTable[editIndex]?.dependentOptions}
                initialValues={editIndex !== null ? dependentsTable[editIndex] : undefined}
                alloweddependentKind={dependentRelationShip}
                currentStep={currentStep}
                isEditModal={isEditModal}
                modalAction={modalAction}
                dependentsTable={dependentsTable}
              />
            </div>
          </div>
        </div>
      </div>

      {show && <div className="modal-backdrop show" onClick={handleClose}></div>}

      <div className="col-12 d-flex justify-content-end">
        {!isdisabled && <button disabled={isdisabled} name="dependent-back-button" className="btn btn-outline-primary primaryOutline btn-outline-primary-contrast ml-3" type="button" onClick={handleParentBack}>Back</button>}
        <button name="dependent-continue-button" className="btn btn-primary primaryBackground btn-primary-contrast ml-3"
          type="button" disabled={hasErrors} onClick={dependentContinue}>{btnLabel}</button>
      </div>
    </div>
  );
});

export default Dependent;
